import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_TICKETS);

export default {
  obtener_tickets() {
    return http.get(`/tickets`);
  },

  obtener_ticket(id) {
    return http.get(`/tickets/${id}`);
  },

  asignar_ticket(id) {
    return http.put(`/tickets/${id}/asignar`);
  },

  reasignar_ticket(id, usuario) {
    return http.put(`/tickets/${id}/reasignar/${usuario}`);
  },

  agregar_comentario(ticket, payload) {
    return http.post(`/tickets/${ticket}/comentarios`, payload);
  },

  agregar_documento(ticket, payload) {
    return http.post(`/tickets/${ticket}/documentos`, payload);
  },

  descargar_documento(ticket, documento) {
    return http.get(`/tickets/${ticket}/documentos/${documento}`, {responseType: 'blob'})
  },

  avanzar_etapa(ticket) {
    return http.put(`/tickets/${ticket}/etapas/avanzar`);
  },

  retroceder_etapa(ticket, payload) {
    return http.put(`/tickets/${ticket}/etapas/retroceder`, payload);
  },

  no_viable(ticket, payload) {
    return http.put(`/tickets/${ticket}/etapas/no_viable`, payload);
  }
}