<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <ul class="comentarios">
          <li class="title">Comentarios</li>
          <template v-if="paginacion_comentarios.length > 0">
          <li v-for="comentario in paginacion_comentarios" :key="comentario.id" class="comentario">
            <div class="detalles" v-html="comentario.comentario"></div>
            <div class="info">
              <div class="usuario">Creado por: {{ comentario.nombre ? comentario.nombre : 'Sistema' }}</div>
              <div class="fecha">{{ $moment(comentario.created_at).format(datetime_format) }}</div>
            </div>
          </li>
          </template>
          <li v-else>No hay comentarios</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <select v-model="filtro_comentarios_aplicar" name="filtro_comentarios" id="filtro_comentarios" class="form-control">
          <option value="comentarios">Visualizar comentarios</option>
          <option value="alertas">Visualizar alertas</option>
          <option value="todo">Visualizar todo</option>
        </select>
      </div>
      <div class="offset-sm-3 col-sm-6">
        <ul v-if="paginador.length > 1" class="pagination justify-content-end pagination-sm">
          <li class="page-item"><a href="" class="page-link" @click.prevent="pagina_anterior">Anterior</a></li>
          <li v-for="pagina in paginador" :class="'page-item'+(pagina_actual == pagina ? ' active' : '')" :key="pagina"><a href="" class="page-link" @click.prevent="pagina_actual=pagina">{{ pagina}}</a></li>
          <li class="page-item"><a href="" class="page-link" @click.prevent="pagina_siguiente">Siguiente</a></li>
        </ul>
      </div>
    </div>

    <div v-if="ticket.etapa_actual != etapa_no_viable" class="row mt-3">
      <div class="col-sm-12">
        <form @submit.prevent="agregar_comentario">
          <div class="input-group mb-3">
            <input v-model="comentario" type="text" class="form-control" placeholder="Comentario..." >
            <div class="input-group-append">
              <button class="btn btn-primary">Agregar comentario</button>
            </div>
          </div>
        </form>
      </div>
    </div>  
  </div>
</template>

<script>
import apiTicket from '@/apps/tickets/api/tickets';

export default {
  props: {
    ticket: {
      type: Object,
      default() {
        return {
          comentarios: []
        }
      }
    }
    ,etapa_no_viable: 0
  }
  ,data() {
    return {
      datetime_format: 'DD MMM YYYY HH:mm',
      pagina_actual: 1,
      por_pagina: 3,
      comentario: null,
      filtro_comentarios_aplicar: 'comentarios'
    }
  }
  ,methods: {
    pagina_anterior() {
      if (this.pagina_actual > 1)
        this.pagina_actual -= 1;
    }
    ,pagina_siguiente() {
      if (this.pagina_actual < this.total_paginas)
        this.pagina_actual += 1;
    }
    ,async agregar_comentario() {
      try {
        if (!this.comentario || this.comentario == '' || this.comentario.length < 5)
          return this.$helper.showMessage('Error','Debes definir el cuerpo del mensaje (mínimo 5 caracteres)','error','alert');

        let res = (await apiTicket.agregar_comentario(this.ticket.id, {comentario: this.comentario})).data;
        this.$log.info('res', res);

        this.comentario = null;
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }

  }
  ,computed: {
    filtro_comentarios() {
      let comentarios = [];

      if (!this.ticket || !this.ticket.comentarios)
        return comentarios;

      this.ticket.comentarios.forEach(comentario => {
        switch(this.filtro_comentarios_aplicar) {
          case 'comentarios':
            if (comentario.nombre != 'Sistema')
              comentarios.push(comentario);
            break;
          case 'alertas':
            if (comentario.nombre == 'Sistema')
              comentarios.push(comentario);
            break;
          default:
            comentarios.push(comentario);
        }
      });

      return comentarios;
    },
    paginacion_comentarios() {
      let comentarios = [];

      if (!this.filtro_comentarios)
        return comentarios;

      let inicio = this.pagina_actual == 1 ? 0 : ((this.pagina_actual-1) * this.por_pagina);
      let final = this.pagina_actual < this.total_paginas ? (inicio+this.por_pagina)-1 : this.filtro_comentarios.length-1;

      for(let i = inicio; i<=final; i++) {
        comentarios.push(this.filtro_comentarios[i]);
      }

      return comentarios;
    },
    total_paginas() {
      console.log('ticket', this.ticket);
      return this.ticket && this.filtro_comentarios ? Math.ceil(this.filtro_comentarios.length / this.por_pagina) : 1;
    },
    paginador() {
      let paginas = [];

      let inicio = this.pagina_actual == 1 ? 1 : (this.pagina_actual <= 4 ? 1 : this.pagina_actual-3);
      // let final = this.pagina_actual <= 2 ? 5 : (this.pagina_actual < this.total_paginas ? (this.pagina_actual < (this.total_paginas-3) ? this.pagina_actual+3 : this.total_paginas) : this.total_paginas);
      let final = this.pagina_actual == this.total_paginas ? this.total_paginas : (this.pagina_actual < (this.total_paginas-3) ? this.pagina_actual+3 : this.total_paginas)

      for(let i=inicio; i<=final; i++) {
        paginas.push(i);
      }

      return paginas;
    }
  }
}
</script>

<style lang="scss">
  .comentarios {
    list-style: none;
    margin: 0px;
    padding: 0px;

    .title {
      background-color: #4c4c4c !important;
      font-size: 1.4em;
      color: #fff;
      padding: 5px 10px !important;
    }

    .comentario {
      background-color: #fff;
      margin-bottom: 7px;
      overflow: hidden;

      .detalles {
        padding: 5px 10px;
        margin: 0px;
      }

      .info {
        font-size: 0.75em;

        .usuario, .fecha {
          background-color: #e0e0e0;
          color: #666666;
          padding: 5px 10px;
          display: inline-block;
        }

        .usuario {
          width: 80%;
        }

        .fecha {
          width: 20%;
          text-align: right;
        }
      }
    }

  }
</style>